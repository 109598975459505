<template>
  <div
    style="height: inherit"
    :class="{ 'is-loading': pLoading }"
  >

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ meta.total }} {{ $t('catalog.list.labels.results') }}
              </div>
            </div>
            <div class="view-options d-flex">

              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
                class="sort-btn"
              >
                <template #button-content>
                  <feather-icon
                    icon="BarChartIcon"
                    size="18"
                    class="mr-1"
                  />
                  {{ sortBy.text }}
                </template>
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group d-none"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col v-if="canFlush" cols="2">
          <b-button
            :variant="'danger'"
            block
            :style="`height: 48px;`"
            @click="flushFilters"
          >
            <feather-icon
              icon="TrashIcon"
              size="16"
              class="align-middle"
            />
          </b-button>
        </b-col>
        <b-col :cols="canFlush ? '10' : '12'">
          <b-input-group class="input-group-merge">
            <b-form-input
              :placeholder="$t('catalog.list.labels.search')"
              class="search-product"
              :value="$route.query.query"
              @input="(val) => initSearch(val)"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section
      v-infinite-scroll="loadMore"
      :class="adaptiveView"
      infinite-scroll-disabled="pLoading"
      infinite-scroll-distance="10"
    >
      <Card
        v-for="(product, index) in products"
        :key="product.id"
        :product="product"
        :hot="false"
        :style="`z-index: ${9999 - index};`"
      />
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <filter-sidebar
        :filter-options-selected="filterOptionsSelected"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :f-loading="fLoading"
      />
    </portal>
    <FloatCart />
  </div>
</template>

<script>
import GlobalMixin from '@mixins/GlobalMixin'
import CatalogMixin from '@mixins/CatalogMixin'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import Card from '@/views/catalog/Card'
import FilterSidebar from '@/views/catalog/FilterSidebar'
import FloatCart from '@/views/catalog/FloatCart'

export default {
  components: {
    Card,
    FilterSidebar,
    FloatCart,
  },
  mixins: [GlobalMixin, CatalogMixin],
  metaInfo: {
    title: 'Каталог телеграм-каналів | TeLeAds',
    meta: [
      { vmid: 'description', name: 'description', content: 'Каталог тг-каналів ✔️ Ручна перевірка ✔️ Широкий асортимент ✔️ Доступна ціна розміщення ✈️ TeLeAds' },
    ],
  },
  data() {
    return {
      name: 'products-index',
    }
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    return {
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.ecommerce-application .grid-view {
  grid-template-columns: 1fr 1fr;
}

.grid-view,
.list-view {
  position: relative;
  z-index: 1;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.is-loading {
  opacity: 0.5;
}
</style>
